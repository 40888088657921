const APPLICATION_PATH = "/";
const TREAMENT_ARV = {
  haveArvTreatment: 1, //Có điều trị ARV
  noArvTratment: 2, //Không điều trị ARV
  noArvTreatmentInformation: 3, //Không có thông tin điều trị arv
};
const methodHandle = {
  edit: 0,
  delete: 1,
  export: 3
};
const listArvHIVinfectedMothers = {
  noInfomation: 1, //Không có thông tin
  yet: 2, //Chưa
  yes: 3, //Có
};
const listDetailArvHIVinfectedMothers = {
  whenPregnant: 1, //Khi mang thai
  duringlabor: 2, //Khi chuyển dạ
  afterChildbirth: 3, //Sau khi sinh
  backupRegimen: 4, //Phác đồ dự phòng
};
const typeHIVinfectedMothers = {
  beforePregnancy: 1, //Trước khi mang thai
  duringPregnancy: 2, //Trong khi mang thai
  duringlabor: 3, //Khi chuyển dạ
  noInformation: 4, //Không có thông tin
};
const typeMethodFeed = {
  neverBreastfed: 1, //Chưa bao giờ bú mẹ
  currentlyBreastfeeding: 2, //Hiện đang bú mẹ
  moreThan6Weeks: 3, //Dừng bú hơn 6 tuần
  lessThan6Weeks: 4, //Dừng bú dưới 6 tuần
  noInformation: 5, //Không có thông tin
};
const typePreventionArv = {
  noInfomation: 1, //Không có thông tin
  yet: 2, //Chưa
  yes: 3, //Có
};
const typeHivAntibody = {
  notTestedYet: 1, //Chưa xét nghiệm
  positive: 2, //dương tính
  negative: 3, //Âm tính
  unknown: 4, //Không xác định
};

const orderTest = {
  EID: 2,
  VL: 1
}

const belongTo = {
  VL: 1,
  EID: 2,
};

const orgType = {
  OPC: 3,
  CDC: 2,
  NIHE: 1,
};
const typeTestResult = {
  ViralLoad: 1,
  EID: 2,
};

const shippingStatus = {
  pending: "Pending",
  accepted: "Accepted",
  cdcDraft: "CdcDraft"
};

const specimenType = {
  // over18M: 1,
  // under18M: 2,
  under18M: 1,
  over18M: 2,
  name: {
    other: "Khác",
    DBS: "DBS",
  },
};

const role = {
  user: "ROLE_USER",
  manager: 6,
};

const orgProvince = {
  HN: "Hà Nội",
};

const listShippingStatus = [
  { id: "Draft", name: "Chưa gửi" },
  { id: "Pending", name: "Đã gửi" },
  { id: "Accepted", name: "Đã nhận" },
];
const listShippingStatusForOPC = [
  { id: "Draft", name: "OPC chưa gửi CDC" },
  { id: "PendingToCdc", name: "OPC đã gửi CDC" },
];
const listShippingStatusForCDC = [
  { id: "CdcDraft", name: "Chưa gửi NIHE" },
  { id: "PendingToNihe", name: "Đã gửi NIHE" },
];
const listShippingStatusForNIHE = [
  { id: "CdcDraft", name: "Chưa gửi NIHE" },
  { id: "PendingToNihe", name: "Đã gửi NIHE" },
  { id: "Accepted", name: "NIHE đã nhận" },
];

const listShippingStatusForAdmin = [
  { id: "Draft", name: "OPC chưa gửi CDC" },
  { id: "PendingToCdc", name: "OPC đã gửi CDC" },
  { id: "CdcDraft", name: "Chưa gửi NIHE" },
  { id: "PendingToNihe", name: "Đã gửi NIHE" },
  { id: "Accepted", name: "NIHE đã nhận" }
]

const listShippingStatusOPCForNIHE = [
  { id: "CdcDraft", name: "Chưa gửi NIHE" },
  { id: "PendingToNihe", name: "Đã gửi NIHE" },
  { id: "Accepted", name: "NIHE đã nhận" },
];

const listPreventionArvRegimen = [
  { id: "TDF + 3TC + DTG", name: "TDF + 3TC + DTG" },
  { id: "TDF + FTC + DTG", name: "TDF + FTC + DTG" },
  { id: "TDF + 3TC + EFV", name: "TDF + 3TC + EFV" },
  { id: "TDF + FTC + EFV", name: "TDF + FTC + EFV" },
  { id: "AZT + 3TC + EFV", name: "AZT + 3TC + EFV" },
  { id: "TDF + 3TC +PI/r", name: "TDF + 3TC +PI/r" },
  { id: "TDF + FTC +PI/r", name: "TDF + FTC +PI/r" },
  { id: "ABC + 3TC + DTG", name: "ABC + 3TC + DTG" },
  { id: "Khác", name: "Khác" },
];

const shippingCardType = {
  normal: 'Normal',
  children: 'Children'
}

const listShippingStatusCommon = {
  draft: "Draft",
  pending: "Pending",
  cdcDraft: "CdcDraft",
};

const listPatientType = {
  // EID: 1,
  // VL: 2,
  VL: 1,
  EID: 2,
};
const OTHER_SPECIMEMN_SOURCE = "other";
const OTHER_SPECIMEMN_DELETE = "delete";
const DEFAULT_SPECIMEN_SOURCE = [
  { code: "other", name: "Khác" },
  { code: "delete", name: "Xóa Nguồn mẫu" },
];

const resultStatus = {
  notConfirm: 3,
  notResult: 1,
  hasResult: 2
}

const listGender = [
  { id: "M", name: "Nam" },
  { id: "F", name: "Nữ" },
  { id: "U", name: "Không rõ" },
];
const SUCCESS_RES = 200

const listResultStatus = [
  { id: 1, name: "Chưa có kết quả" },
  { id: 3, name: "Kết quả chưa được xác nhận" },
  { id: 2, name: "Đã có kết quả" },
];

const listTypeStatus = [
  { name: "Dương tính", type: 1 },
  { name: "Âm tính", type: 2 },
  { name: "Không xác định", type: 3 }
]

const statusOfShipping = [
  { id: 1, status: "Pending", name: "Đã gửi" },
  { id: 2, status: "Draft", name: "Chưa gửi" },
  { id: 3, status: "Accepted", name: "Đã nhận" },
  { id: 4, status: "CdcDraft", name: "Chưa gửi NIHE" },
  // { id: 5, status: "CdcAccepted", name: "CDC đã nhận" },
  { id: 6, status: "PendingToCdc", name: "Đã gửi CDC" },
  { id: 7, status: "PendingToNihe", name: "Đã gửi NIHE" },
];

const listKindOfResults = [
  { id: 1, kind: "Bé hơn 20" },
  { id: 2, kind: "Bé hơn 40" },
  { id: 3, kind: "Bé hơn 50" },
  { id: 4, kind: "Từ 50 đến 1000" },
  { id: 5, kind: "Lớn hơn 1000" },
  { id: 6, kind: "KPH" }
]

const listSpecimenSource = [
  { name: "Quỹ Toàn Cầu", type: 1 },
  { name: "HepC", type: 2 }
];

const searchStatus = {
  OPCtoCDC: 1,
  CDCtoNIHE: 2,
};

const listSourceSpecimen = [
  { id: 1, name: "Quỹ toàn cầu" },
  { id: 2, name: "Recency" },
  { id: 3, name: "Dịch vụ" },
  { id: 4, name: "Khác" },
];

const listFieldConst = {
  orgType: "orgType",
  usingSms: "usingSms"
}

const keyConst = {
  enter: "Enter"
}

const exportType = {
  excel: "excel",
  pdf: "pdf",
  individual: "individual"
}
//const APPLICATION_PATH="/asset_develop/";//Đặt homepage tại package.json giống như tại đây nếu deploy develop
module.exports = Object.freeze({
  //ROOT_PATH : "/egret/",
  ROOT_PATH: APPLICATION_PATH,
  ACTIVE_LAYOUT: "layout1", //layout1 = vertical, layout2=horizontal
  API_ENPOINT: "http://nihesms.oceantech.vn/nihe", //PROD
  // API_ENPOINT: "http://localhost:8893/nihe", //local
  //API_ENPOINT: "http://nihedev.oceantech.com.vn/nihe", //Dev
  LOGIN_PAGE: APPLICATION_PATH + "session/signin", //Nếu là Spring
  HOME_PAGE: APPLICATION_PATH + "dashboard/analytics", //Nếu là Spring
  //HOME_PAGE:APPLICATION_PATH+"dashboard/learning-management"//Nếu là Keycloak
  //HOME_PAGE:APPLICATION_PATH+"landing3",//Link trang landing khi bắt đầu
  MATERIAL_DEPARTMENT_CODE: "VPB4",
  TREAMENT_ARV: TREAMENT_ARV,
  //ListPatientType
  childrenUnder18Months: 2, //Trẻ em
  adults: 1, //người lớn
  shippingCardType: shippingCardType,
  keyConst: keyConst,
  exportType: exportType,
  listResultStatus: listResultStatus,
  listTypeStatus: listTypeStatus,
  listGender: listGender,
  orderTest: orderTest,
  hivTreatment: 3, //Bệnh nhân điều trị hiv
  listArvHIVinfectedMothers: listArvHIVinfectedMothers,
  listDetailArvHIVinfectedMothers: listDetailArvHIVinfectedMothers,
  orgType: orgType,
  typeHIVinfectedMothers: typeHIVinfectedMothers,
  typeMethodFeed: typeMethodFeed,
  shippingStatus: shippingStatus,
  listKindOfResults: listKindOfResults,
  listSpecimenSource: listSpecimenSource,
  typeTestResult: typeTestResult,
  typePreventionArv: typePreventionArv,
  typeHivAntibody: typeHivAntibody,
  specimenType: specimenType,
  searchStatus: searchStatus,
  listPreventionArvRegimen: listPreventionArvRegimen,
  methodHandle: methodHandle,
  listShippingStatusForAdmin: listShippingStatusForAdmin,
  orgProvince: orgProvince,
  listPatientType: listPatientType,
  listShippingStatus: listShippingStatus,
  listShippingStatusForCDC: listShippingStatusForCDC,
  listShippingStatusForOPC: listShippingStatusForOPC,
  listShippingStatusOPCForNIHE: listShippingStatusOPCForNIHE,
  listShippingStatusForNIHE: listShippingStatusForNIHE,
  statusOfShipping: statusOfShipping,
  role: role,
  listSourceSpecimen: listSourceSpecimen,
  belongTo: belongTo,
  resultStatus: resultStatus,
  listShippingStatusCommon: listShippingStatusCommon,
  listFieldConst: listFieldConst,
  OTHER_SPECIMEMN_SOURCE: OTHER_SPECIMEMN_SOURCE,
  OTHER_SPECIMEMN_DELETE: OTHER_SPECIMEMN_DELETE,
  SUCCESS_RES: SUCCESS_RES,
  DEFAULT_SPECIMEN_SOURCE: DEFAULT_SPECIMEN_SOURCE,
});
